import React from 'react';
import { Link } from 'gatsby';

import Button from '../Button';
import './index.css';

export default ({ text, buttonText, link }) => {
  return (
    <div className="cta-banner secondary-padding">
      <p className="left">{text}</p>
      <Link to={link}>
        <Button text={buttonText} />
      </Link>
    </div>
  );
};
