import React from 'react';

import './index.css';

import workDots from '../../img/work-dots.svg';
import orangeEllipse from '../../img/orange-ellipse.svg';
import Img from 'gatsby-image';

const TechnologyHero = ({ title, description, image, body }) => {
  return (
    <section className="general-hero">
      <div className="hero-container">
        <div className="left root-padding">
          <img
            className="dots"
            src={workDots}
            alt="Dots"
          />
          <img
            className="dots-bottom"
            src={workDots}
            alt="Dots"
          />
          <img
            className="orange-ellipse"
            src={orangeEllipse}
            alt="orange-ellipse"
          />
          <div className="red-dot" />
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
        <div className="right">
          <Img fluid={image.childImageSharp.fluid} />
        </div>
      </div>
      <div className="content-container secondary-padding">{body}</div>
    </section>
  );
};

export default TechnologyHero;
