import React from 'react';
import './index.css';

export default ({ text, className, onClick }) => {
  return (
    <div onClick={onClick} className={`c-button ${className}`}>
      <p>{text}</p>
    </div>
  );
};

export const TButton = ({ text, className }) => (
  <div className={`t-button ${className}`}>
    {text}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.486"
      height="12.603"
      viewBox="0 0 16.486 12.603"
    >
      <g transform="translate(0)">
        <g transform="translate(0 0)">
          <path
            className="a"
            d="M18.622,19.111l-5.085-5.085a1.215,1.215,0,0,0-1.718,1.718l3,3.02H3.715a1.215,1.215,0,0,0,0,2.43H14.821l-3,3a1.221,1.221,0,1,0,1.735,1.718l5.067-5.067a1.235,1.235,0,0,0,.364-.868A1.167,1.167,0,0,0,18.622,19.111Z"
            transform="translate(-2.5 -13.675)"
          />
        </g>
      </g>
    </svg>
  </div>
);
