import React, { useRef } from 'react';

import './index.css';

import BestBlogs from '../../BestBlogs';

const IotBestBlogs = ({items, title}) => {

  return (
    <section className='iot-best-blogs'>
      <BestBlogs items={items} title={title} />
    </section>
  )

}

export default IotBestBlogs;