import React from 'react';
import Img from 'gatsby-image';

import './index.css';

export default ({item}) => {
  return (
    <section className="iot-embedded-second">
      <div className="center secondary-padding">
        <p className="c-title">How We Can Help Design and Develop Your IoT Product</p>
        <p className="subtitle">
          We approach IoT software development in three stages, each building upon the last. 
          Your IoT strategy includes hardware, architecture, and all necessary application 
          development.   
        </p>
          <div className='iot-image-container'>
          <Img 
            className='three-areas-iot'
            fluid={item.childImageSharp.fluid}
            alt="Three Areas of Iot"
          />
        </div>
      </div>
    </section>
  );
};

