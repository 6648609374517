import React, { useRef, Fragment } from 'react';
import { Link } from 'gatsby';
import { useCompleteOnScreen } from '../../../utils/hooks';

import { AnimatedDots } from '../../Dots';
import { TButton } from '../../Button';
import FullWorkCard from '../../../components/workCards/FullWorkCard';
import './index.css';



export default ({ title, items}) => {
  const ref = useRef();
  const completeOnScreen = useCompleteOnScreen(ref);
  const recentWork = items[0]
  return (
    <>
    <section className='root-padding iot-recent-work'>
      <div className="top">
        <div>
          <AnimatedDots completeOnScreen={completeOnScreen} />
          <h2 ref={ref}>{title}</h2>
        </div>
        <Link to="/work" className="redirect-link">
          <TButton text="see more" />
        </Link>
      </div>
      <FullWorkCard caseStudy={recentWork} />
    </section>
  </>
  );
};
