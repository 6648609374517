import React, { useRef } from 'react';

import { Link } from 'gatsby';

import './index.css';
import { useCompleteOnScreen } from '../../utils/hooks';
import { formatDate } from '../../utils/index';
import { AnimatedDots } from '../Dots';
import uxBlogs from '../../img/blog-images@2x.png';

export default ({ items, title }) => {
  const ref = useRef();
  const completeOnScreen = useCompleteOnScreen(ref);

  return (
    <section ref={ref} className="best-blogs root-padding">
      <div className="left">
        <p className="c-title">{title}</p>
        <AnimatedDots completeOnScreen={completeOnScreen} />
        <div className="blog-ux-card-container">
          {items.map((blog, index) => (
            <div key={index} className="blog-ux-card">
              <Link to={blog.slug}>
                <p className="c-title">{blog.title}</p>
                <div className="bottom">
                  <div>
                    <span className="date">{formatDate(blog.date)}</span>
                    <span className="read">{blog.readingTime}</span>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="60.215"
                    height="60.215"
                    viewBox="0 0 60.215 60.215"
                  >
                    <g
                      id="Group_6657"
                      data-name="Group 6657"
                      transform="translate(-558.441 -4121.392)"
                    >
                      <g
                        id="noun_Arrow_1920806"
                        transform="translate(579.695 4144.373)"
                      >
                        <path
                          id="Path_540"
                          data-name="Path 540"
                          d="M17.063,5.753,11.682.372a1.3,1.3,0,0,0-1.818,0,1.3,1.3,0,0,0,0,1.818l3.178,3.2H1.286a1.286,1.286,0,1,0,0,2.571H13.041L9.863,11.135A1.292,1.292,0,1,0,11.7,12.953L17.063,7.59a1.308,1.308,0,0,0,.386-.918A1.235,1.235,0,0,0,17.063,5.753Z"
                          fill="#004374"
                        />
                      </g>
                      <g
                        id="Ellipse_87"
                        data-name="Ellipse 87"
                        transform="translate(588.548 4127.049) rotate(45)"
                        fill="none"
                        stroke="#004374"
                        strokeWidth="4"
                      >
                        <circle
                          cx="17.289"
                          cy="17.289"
                          r="17.289"
                          stroke="none"
                        />
                        <circle
                          className="outer-circle"
                          cx="17.289"
                          cy="17.289"
                          r="19.289"
                          fill="none"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <img src={uxBlogs} alt="Ux blogs" />
    </section>
  );
};
