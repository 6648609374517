export const formatDate = (date) => {
  const dateObj = new Date(date);
  const month = dateObj.getUTCMonth() + 1;
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();
  return `${month}.${day}.${year}`;
};

export const isOdd = (number) => {
  return number % 2;
};
