import React from 'react';

import './index.css';

const HeroBody = () => {
  return (
    <section className="iot-embedded-hero-body-container">
      <p>
        Along with the rise of connected consumer devices, there has been an 
        even bigger shift in how businesses are using the Internet of Things. 
        Today, businesses are building entire systems to improve efficiency, 
        quality, transparency, and adaptability through data.
        <br />
        <br />
        Whether you’re in energy, buildings, healthcare, life sciences, or supply 
        chains, we can help you create robust systems, apps, and dashboards. Our 
        team of software engineers has experience with PCB strategy, embedded 
        development, architecture, machine learning, and more. Contact Devetry to 
        develop your next IoT device. 
      </p>
    </section>
  );
};

export default HeroBody;
