import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import AppContext from '../../contexts/AppContext';

import './index.css';

// Sections
import Hero from '../../components/TechnologyHero';
import HeroBody from '../../components/iotEmbeddedSections/HeroBody';
import Second from '../../components/iotEmbeddedSections/Second'
import RecentWork from '../../components/iotEmbeddedSections/RecentWork';
import BestBlogs from '../../components/iotEmbeddedSections/BestBlogs';
import CTABanner from '../../components/CTABanner';


const pageTitle =
  'IoT Software Development | Embedded Development Services | Devetry ';
const pageDescription =
  'Explore IoT software development, including hardware strategy and platform implementation, from the team at Devetry.';

const IotEmbeddedPageTemplate = ({ recentWork, blogs, item, heroImage }) => {
  const { setActivePage } = useContext(AppContext);

  useEffect(() => {
    setActivePage('services');
  }, [setActivePage]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
      </Helmet>
      <main className="salesforce-section root-container">
        <Hero
          title="IoT & Embedded Development"
          description="Leverage data and make better business decisions."
          image={heroImage}
          body={<HeroBody />}
        />
        <Second item={item}/>
        <RecentWork title="Recent work" items={recentWork}/>
        <BestBlogs items={blogs} title="Refine your strategic skills" />
        <CTABanner
          text="Let’s build something awesome"
          buttonText="SEE ALL OUR PROJECTS"
          link="/work"
        />
      </main>
    </>
  )
}

const IotEmbeddedPage = ({ data }) => {
  const blogs = data.blogs.edges.map((e) => ({
    readingTime: e.node.fields.readingTime.text,
    slug: e.node.fields.slug,
    ...e.node.frontmatter
  }));
  const recentWork = data.recentWork.edges.map((e) => ({
    ...e.node.frontmatter,
    slug: e.node.fields.slug
  }));

  const item = data.file;
  const heroImage = data.hero;

  return <IotEmbeddedPageTemplate recentWork={recentWork} blogs={blogs} item={item} heroImage={heroImage}/>;
};

IotEmbeddedPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IotEmbeddedPage;


export const pageQuery = graphql`
  query IotEmbeddedPageTemplate {
    recentWork: allMarkdownRemark(
      filter: { 
        frontmatter: { 
          templateKey: { eq: "CaseStudy/index" } 
        } }
      limit: 1
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            heroLogo {
              src {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
            cardImage {
              src {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
            cardTitle
            cardSubtitle
            tags
          }
        }
      }
    }
    blogs: allMarkdownRemark(
      filter: {
        published: { eq: true },
        frontmatter: {
          templateKey: { eq: "Blog/index" }
          category: { eq: "Development" }
        }
      }
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            date
            category
          }
        }
      }
    }
    file(relativePath: {
      eq: "iot-development-2x.png"
    }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hero: file(relativePath: {
      eq: "product-design.jpg"
    }) {
      childImageSharp {
        fluid(quality: 60, maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
