import React from 'react';

import './index.css';

export default () => {
  return (
    <svg
      className="c-arrow"
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
    >
      <g transform="translate(-155 6)">
        <g transform="translate(166.611 17.197) rotate(-45)">
          <g transform="translate(0 0)">
            <path
              className="a"
              d="M15.79,5.324,10.81.344a1.2,1.2,0,0,0-1.683,0,1.2,1.2,0,0,0,0,1.683l2.94,2.957H1.19a1.19,1.19,0,0,0,0,2.38H12.068L9.127,10.3a1.2,1.2,0,1,0,1.7,1.683L15.79,7.024a1.21,1.21,0,0,0,.357-.85A1.143,1.143,0,0,0,15.79,5.324Z"
            />
          </g>
        </g>
        <g className="b" transform="translate(161)">
          <circle className="c" cx="16" cy="16" r="16" />
          <circle className="d" cx="16" cy="16" r="19" />
        </g>
      </g>
    </svg>
  );
};
